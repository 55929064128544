import React from "react"
import { ThemeProvider } from "@material-ui/core/styles"
import { createMuiTheme } from "@material-ui/core"

import GlobalContextProvider from "./src/context/GlobalContextProvider"
import AuthenticationProvider from "./src/services/authenticationProvider"

import "./src/styles/global.css"
import "./src/styles/stripe.css"

import { createInstance, OptimizelyProvider } from "@optimizely/react-sdk"

const optimizely = createInstance({
  sdkKey: process.env.GATSBY_OPTIMIZELY_SDK_KEY,
})

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#000000",
      light: "#181818",
      danger: "#F33434",
      active: "#282828",
      partialBorder: "#EEEECC",
    },
    secondary: {
      main: "#F3A634",
    },
  },
  typography: {
    fontFamily: "mundial, sans-serif",
    size: {
      sidebar: "16px",
    },
    color: {
      light: "#FFFFFF",
      dark: "#ADADAD",
      hover: "#474747",
    },
  },
  props: {
    MuiButtonBase: {
      // Disable ripple globally.
      disableRipple: true,
      // The class will be added (as is, ie `focus-visible`)
      // To the ButtonBase root whenever the button gets focus via keyboard.
      focusVisibleClassName: "focus-visible",
    },
  },
  overrides: {
    MuiButtonBase: {
      // And this is how we select it.
      root: {
        "&.focus-visible": {
          outline: "-webkit-focus-ring-color auto 2px !important",
        },
      },
    },
  },
})

export const wrapRootElement = ({ element }) => {
  return (
    <OptimizelyProvider optimizely={optimizely}>
      <ThemeProvider theme={theme}>
        <GlobalContextProvider>
          <AuthenticationProvider>{element}</AuthenticationProvider>
        </GlobalContextProvider>
      </ThemeProvider>
    </OptimizelyProvider>
  )
}
