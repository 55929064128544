import React, { useEffect, useContext, useCallback } from "react"

import { getMe as tenantsApiGetMe } from "./tenantsApiV2"

import { refreshToken as tenantsApiRefreshToken } from "./tenantsApiV2"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"

const AuthenticationProvider = ({ children }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const context = useContext(GlobalStateContext)

  // Manages existing credentials and refreshing for tenants
  useEffect(() => {
    const checkUserStatus = async () => {
      if (!context.tenantUser || !context.tenantUser.email) {
        if (context.loggedInToTenantsPortal) {
          try {
            const data = await tenantsApiGetMe()
            if (data.value) {
              dispatch({
                type: "storeTenantUser",
                tenantUser: data.value,
              })
            }
          } catch (e) {
            console.log(e)
          }
        } else {
          try {
            const tokensStr = localStorage.getItem("theBunch-tenantTokens")
            let tokens = tokensStr && JSON.parse(tokensStr)

            if (tokens && tokens.accessToken && tokens.refreshToken) {
              const data = await tenantsApiRefreshToken(tokens.refreshToken)
              if (data.value.accessToken) {
                dispatch({
                  type: "loggedInToTenantsPortal",
                  tokens: {
                    accessToken: data.value.accessToken,
                    refreshToken: data.value.refreshToken,
                  },
                })

                setInterval(async () => {
                  try {
                    const existingTokensStr = localStorage.getItem(
                      "theBunch-tenantTokens"
                    )

                    if (existingTokensStr) {
                      const existingTokens = JSON.parse(existingTokensStr)
                      const refreshTokenData = await tenantsApiRefreshToken(
                        existingTokens.refreshToken
                      )
                      dispatch({
                        type: "loggedInToTenantsPortal",
                        tokens: {
                          accessToken: refreshTokenData.value.accessToken,
                          refreshToken: refreshTokenData.value.refreshToken,
                        },
                      })
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }, 600000)
              } else {
                dispatch({
                  type: "tenantUserCheckComplete",
                })
              }
            } else {
              dispatch({
                type: "tenantUserCheckComplete",
              })
            }
          } catch (e) {
            console.log(e)
          }
        }
      }
    }
    checkUserStatus()
  }, [dispatch, context.loggedInToTenantsPortal, context.tenantUser])

  // Manages existing credentials and refreshing for tenants just for meter reading requests
  useEffect(() => {
    const checkUserStatus = async () => {
      // Only run the meter reading only check, once the full tenant login check has been completed, and the user isn't logged in
      if (
        context.tenantUserCheckComplete &&
        !context.loggedInToTenantsPortal &&
        (!context.tenantUser || !context.tenantUser.email)
      ) {
        if (context.loggedInToTenantsPortalForMeterReadings) {
          try {
            const data = await tenantsApiGetMe()
            if (data.value) {
              dispatch({
                type: "storeTenantUser",
                tenantUser: data.value,
              })
            }
          } catch (e) {
            console.log(e)
          }
        } else {
          try {
            const tokensStr = localStorage.getItem(
              "theBunch-meterReadingOnlyTokens"
            )
            let tokens = tokensStr && JSON.parse(tokensStr)

            if (tokens && tokens.accessToken && tokens.refreshToken) {
              const data = await tenantsApiRefreshToken(tokens.refreshToken)
              if (data.value.accessToken) {
                dispatch({
                  type: "loggedInToTenantsPortalForMeterReadings",
                  tokens: {
                    accessToken: data.value.accessToken,
                    refreshToken: data.value.refreshToken,
                  },
                })

                setInterval(async () => {
                  try {
                    const existingTokensStr = localStorage.getItem(
                      "theBunch-meterReadingOnlyTokens"
                    )

                    if (existingTokensStr) {
                      const existingTokens = JSON.parse(existingTokensStr)
                      const refreshTokenData = await tenantsApiRefreshToken(
                        existingTokens.refreshToken
                      )
                      dispatch({
                        type: "loggedInToTenantsPortalForMeterReadings",
                        tokens: {
                          accessToken: refreshTokenData.accessToken,
                          refreshToken: refreshTokenData.refreshToken,
                        },
                      })
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }, 600000)
              } else {
                dispatch({
                  type: "tenantUserMeterReadingOnlyCheckComplete",
                })
              }
            } else {
              dispatch({
                type: "tenantUserMeterReadingOnlyCheckComplete",
              })
            }
          } catch (e) {
            console.log(e)
          }
        }
      }
    }
    checkUserStatus()
  }, [
    dispatch,
    context.tenantUserCheckComplete,
    context.loggedInToTenantsPortal,
    context.loggedInToTenantsPortalForMeterReadings,
    context.tenantUser,
  ])

  useEffect(() => {
    if (
      context.tenantUserCheckComplete &&
      context.tenantUserMeterReadingOnlyCheckComplete
    ) {
      dispatch({
        type: "portalLoaded",
      })
    }
  }, [
    dispatch,
    context.tenantUserCheckComplete,
    context.tenantUserMeterReadingOnlyCheckComplete,
  ])

  return <>{children}</>
}

export default AuthenticationProvider
