import React from "react"

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  loggedIn: false,
  portalLoaded: false,
  priceModeView: "week",
  individualPriceModeView: "bill-payer"
}

function reducer(state, action) {
  switch (action.type) {
    case "portalLoaded": {
      return {
        ...state,
        portalLoaded: true,
      }
    }

    case "tenantUserCheckComplete": {
      return {
        ...state,
        tenantUserCheckComplete: true,
      }
    }

    case "tenantUserMeterReadingOnlyCheckComplete": {
      return {
        ...state,
        tenantUserMeterReadingOnlyCheckComplete: true,
      }
    }

    case "loggedInToTenantsPortal": {
      if (!state.loggedInAsAdmin) {
        localStorage.setItem(
          "theBunch-tenantTokens",
          JSON.stringify(action.tokens)
        )
        localStorage.removeItem("theBunch-meterReadingOnlyTokens")
        return {
          ...state,
          loggedInToTenantsPortal: true,
          loggedInToTenantsPortalForMeterReadings: false,
          loggedInToTenantsPortalForUpdatingPaymentDetails: false,
          loggedInAsAdmin: action.adminMode,
          portalLoaded: true,
        }
      }
    }

    case "loggedInToTenantsPortalForMeterReadings": {
      localStorage.setItem(
        "theBunch-meterReadingOnlyTokens",
        JSON.stringify(action.tokens)
      )
      if (state.loggedInAsAdmin) {
        localStorage.setItem(
          "theBunch-tenantTokens",
          JSON.stringify(action.tokens)
        )
      }
      return {
        ...state,
        loggedInToTenantsPortalForMeterReadings: true,
        loggedInAsAdmin: false,
        portalLoaded: true,
      }
    }

    case "loggedInToTenantsPortalForUpdatingPaymentDetails": {
      localStorage.setItem(
        "theBunch-updatePaymentDetailsOnlyTokens",
        JSON.stringify(action.tokens)
      )
      if (state.loggedInAsAdmin) {
        localStorage.setItem(
          "theBunch-tenantTokens",
          JSON.stringify(action.tokens)
        )
      }
      return {
        ...state,
        loggedInToTenantsPortalForUpdatingPaymentDetails: true,
        loggedInAsAdmin: false,
        portalLoaded: true,
      }
    }

    case "logoutOfTenantsPortal": {
      localStorage.removeItem("theBunch-tenantTokens")
      localStorage.removeItem("theBunch-meterReadingOnlyTokens")
      localStorage.removeItem("theBunch-updatePaymentDetailsOnlyTokens")
      return {
        ...state,
        loggedInToTenantsPortal: false,
        loggedInToTenantsPortalForMeterReadings: false,
        loggedInToTenantsPortalForUpdatingPaymentDetails: false,
        tenantUser: null,
        loggedInAsAdmin: false,
      }
    }

    case "storeTenantUser": {
      try {
        if (window.intercomSettings) {
          window.intercomSettings = {
            app_id: 'ttny5ygb',
            user_id: action.tenantUser.id,
            name: `${action.tenantUser.firstName} ${action.tenantUser.lastName}`,
            email: action.tenantUser.email,
          };
        }
      } catch (e) {
        console.log(e)
      }
      return {
        ...state,
        tenantUser: action.tenantUser,
      }
    }

    case "loggedInToAgencyPortal": {
      localStorage.setItem(
        "theBunch-agencyTokens",
        JSON.stringify(action.tokens)
      )
      return {
        ...state,
        loggedInToAgencyPortal: true,
      }
    }

    case "openMobileNavbar": {
      if (document.getElementById("page-wrapper"))
        document.getElementById("page-wrapper").style.overflow = "hidden"
      return {
        ...state,
        showMobileNavbar: true,
      }
    }

    case "closeMobileNavbar": {
      if (document.getElementById("page-wrapper"))
        document.getElementById("page-wrapper").style.overflow = "scroll"
      return {
        ...state,
        showMobileNavbar: false,
      }
    }

    case "setPriceModeView": {
      return {
        ...state,
        priceModeView: action.priceModeView,
      }
    }

    case "setIndividualPriceModeView": {
      return {
        ...state,
        individualPriceModeView: action.individualPriceModeView
      }
    }

    default:
      throw new Error("Bad Action Type")
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
